<template>
  <g>
    <g id="g915" clip-path="url(#clipPath919)"
      ><g id="g921" transform="rotate(90,-1879,9957.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path923"/></g
      ><g id="g925" transform="rotate(90,-1150,3482.5)"
        ><path
          d="M 0,-154.5 C 85.328,-154.5 154.5,-85.328 154.5,0 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 -154.5,-85.328 -85.328,-154.5 0,-154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path927"/></g
      ><g id="g929" transform="rotate(90,-847,9379.5)"
        ><path
          d="M 0,-154.5 C 85.328,-154.5 154.5,-85.328 154.5,0 154.5,85.328 85.328,154.5 0,154.5 -85.328,154.5 -154.5,85.328 -154.5,0 -154.5,-85.328 -85.328,-154.5 0,-154.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path931"/></g
      ><g id="g933" transform="rotate(90,-1614.5,9693)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path935"/></g
      ><g id="g937" transform="rotate(90,1731,7175.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path939"/></g
      ><g id="g941" transform="rotate(90,1277,7629.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path943"/></g
      ><g id="g945" transform="rotate(90,1825,3607.5)"
        ><path
          d="M 0,-113.5 C 62.68432,-113.5 113.5,-62.68432 113.5,0 113.5,62.68432 62.68432,113.5 0,113.5 -62.68432,113.5 -113.5,62.68432 -113.5,0 -113.5,-62.68432 -62.68432,-113.5 0,-113.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path947"/></g
      ><g id="g949" transform="rotate(90,275,2057.5)"
        ><path
          d="M 0,-56.5 C 31.20409,-56.5 56.5,-31.20409 56.5,0 56.5,31.20409 31.20409,56.5 0,56.5 -31.20409,56.5 -56.5,31.20409 -56.5,0 -56.5,-31.20409 -31.20409,-56.5 0,-56.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path951"/></g
      ><g id="g953" transform="rotate(90,3375,5157.5)"
        ><path
          d="M 0,-56.5 C 31.20409,-56.5 56.5,-31.20409 56.5,0 56.5,31.20409 31.20409,56.5 0,56.5 -31.20409,56.5 -56.5,31.20409 -56.5,0 -56.5,-31.20409 -31.20409,-56.5 0,-56.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path955"/></g
      ><g id="g957" transform="rotate(-90,4551.5,-2563)"
        ><path
          d="M 0,-81.5 C 45.01121,-81.5 81.5,-45.01121 81.5,0 81.5,45.01121 45.01121,81.5 0,81.5 -45.01121,81.5 -81.5,45.01121 -81.5,0 -81.5,-45.01121 -45.01121,-81.5 0,-81.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path959"/></g
      ><g id="g961" transform="rotate(-90,2869.5,-881)"
        ><path
          d="M 0,-81.5 C 45.01121,-81.5 81.5,-45.01121 81.5,0 81.5,45.01121 45.01121,81.5 0,81.5 -45.01121,81.5 -81.5,45.01121 -81.5,0 -81.5,-45.01121 -45.01121,-81.5 0,-81.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path963"/></g
      ><g id="g965" transform="rotate(90,-2388,4720.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path967"/></g
      ><g id="g969" transform="rotate(90,712,7820.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path971"/></g
      ><g id="g973" transform="rotate(-90,6957,4624.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path975"/></g
      ><g id="g977" transform="rotate(-90,10057,1524.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path979"/></g
      ><g id="g981" transform="rotate(-90,2799,466.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path983"/></g
      ><g id="g985" transform="rotate(-90,5899,-2633.5)"
        ><path
          d="M 0,-75.5 C 41.6975,-75.5 75.5,-41.6975 75.5,0 75.5,41.6975 41.6975,75.5 0,75.5 -41.6975,75.5 -75.5,41.6975 -75.5,0 -75.5,-41.6975 -41.6975,-75.5 0,-75.5"
          style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
          id="path987"/></g
      ><path
        d="m 1463,1551 h 7938 v 10777 l -109,189 H 1572 l -109,-189 V 1551"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path989"/><path
        d="m 9292,12517 -66,113"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path991"/><path
        d="M 9226,12630 H 1638 l -66,-113"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path993"/><path
        d="M 9401,1551 V 1400 H 1463 v 151"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path995"/></g
  ></g>
</template>
<script>
export default {
  name: "PressureMa11Top",
};
</script>
